<template>
  <div>
    <div style="margin: 10px 0 10px 10px">
      <el-row>
        <el-button type="primary" plain icon="el-icon-plus" @click="addCre()">新增</el-button>
        <el-button type="danger" plain icon="el-icon-refresh" @click="refreshGoodsUp()">更新商品认证数据</el-button>
      </el-row>
      <div style="margin-top:10px;color:#909090">
        sku:
        <el-input @change="findSku2c" v-model="pagination.sku" size="mini" style="width:200px;" />
        认证ID:
        <el-input @change="findSku2c" v-model="pagination.cid" size="mini" style="width:200px;" />
      </div>
    </div>

    <div class="add_dialog">
      <el-dialog :title="`${form.id ? '编辑' : '新增'}认证文件`" :visible.sync="dialogVisible" width="60%" @close="handleDialogClose" :before-close="handleClose" center>
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">认证形式：</p>
          <div class="addAdmin_select">
            <el-select v-model="form.form" style="width: 100%" clearable>
              <el-option v-for="item in formList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">认证类型：</p>
          <el-select v-model="form.type" placeholder="请选择认证类型" class="addAdmin_select" multiple clearable filterable>
            <el-option v-for="(item, i) in typeList" :key="i" :label="item.name" :value="item.id">
              {{ item.name }}
            </el-option>
          </el-select>
        </div>

        <div class="addAdmin">
          <span class="addAdmin_CharacterSet">有效时间：</span>
          <el-date-picker v-model="form.va_time" class="addAdmin_select" type="date" format="yyyy-MM-dd" value-format="timestamp" placeholder="选择日期" />
        </div>

        <div class="addAdmin">
          <span class="addAdmin_CharacterSet">上传文件：</span>
          <el-upload ref="upload" :action="`/a1/upload/file`" :http-request="handleUpload" accept=".pdf" :show-file-list="false">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <p style="margin-left: 10px; color: red">(只能上传PDF格式文件)</p>
        </div>

        <div class="file-list">
          <div class="file-list__item" v-for="(item, index) in uploadFileList" :key="index">
            <div class="file-list__item__label">文件{{ index + 1 }}:</div>
            <div class="file-list__item__name">{{ item.url }}</div>
            <el-button type="danger" @click="handleDelFile(index)">删除</el-button>
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button v-loading="isLoading.table" type="primary" @click="handleDialogConfirm">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="table_style">
      <el-table ref="multipleTable" v-loading="isLoading.table" header-align="center" border class="main-table" :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="type" label="认证类型" align="center" />
        <el-table-column prop="form" label="认证形式" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.form === 1">报告</span>
            <span v-if="row.form === 2">证书</span>
            <span v-if="row.form === 3">报告+证书</span>
          </template>
        </el-table-column>
        <el-table-column prop="va_time" label="有效时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.va_time
                ? new Date(row.va_time * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作" align="center" width="300">
          <template slot-scope="{ row, $index }">
            <div>
              <el-button size="small" icon="el-icon-view" type="info" plain @click="getCertifiedGoods(row.id)">查看</el-button>
              <el-button type="warning" size="small" plain icon="el-icon-edit-outline" style="margin-right: 10px" @click.stop="handleEditAuth(row)">编辑</el-button>

              <el-popover trigger="click" placement="top">
                <p>
                  你确认要删除<strong>{{ row.id }}</strong>号文件吗?
                </p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="hidePopover()">取消</el-button>
                  <el-button type="primary" size="mini" @click="handleDeleteAuth(row.id, $index)">确认
                  </el-button>
                </div>
                <el-button slot="reference" plain icon="el-icon-delete" type="danger" size="small" @click.stop>删除</el-button>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 查看认证文件商品抽屉 -->
    <el-drawer title="已绑定商品" :visible.sync="drawer" :before-close="handleCloseGoods">
      <div style="margin: 0 0 10px 10px">
        <el-row>
          <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </el-row>
      </div>
      <div class="add_dialog">
        <el-dialog title="新增商品资料" :visible.sync="dialogGoodsVisible" :append-to-body="true" width="600px" @close="handleDialogGoodsClose" :before-close="handleClose" center>
          <div class="addAdmin">
            <p class="addAdmin_CharacterSet">证书ID：</p>
            <div class="addAdmin_select">
              <el-input v-model="goods.cid" placeholder="请输入证书ID" disabled />
            </div>
          </div>
          <div class="addAdmin">
            <p class="addAdmin_CharacterSet">商品编码：</p>
            <div class="addAdmin_select">
              <el-input v-model="goods.code" placeholder="请输入商品编码" clearable />
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogGoodsVisible = false">取 消</el-button>
            <el-button v-loading="isLoading.goodsTable" type="primary" @click="handleDialogGoodsConfirm">确 定
            </el-button>
          </div>
        </el-dialog>
      </div>

      <div class="table_style">
        <el-table ref="multipleTable" v-loading="isLoading.goodstable" header-align="center" border class="main-table" :data="goodsData" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="id" label="ID" align="center" width="50px" />
          <el-table-column prop="cid" label="证书ID" align="center" width="80px" />
          <el-table-column prop="code" label="商品编码" align="center">
            <template slot-scope="scope">
              <div class="media">
                <img height="60" :src="`http://hwimg.xmvogue.com/thumb/${scope.row.code}.jpg?x-oss-process=style/440`" class="mr-3" alt="..." />
                <p style="font-size: 14px">商品编码： {{ scope.row.code }}</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="operation" label="操作" align="center" width="100">
            <template slot-scope="{ row, $index }">
              <div>
                <el-popover trigger="click" placement="top">
                  <p>你确认要删除此认证商品吗?</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="hidePopover()">取消</el-button>
                    <el-button type="primary" size="mini" @click="handleDelGoodsAuth(row.id, $index)">确认</el-button>
                  </div>
                  <el-button slot="reference" plain icon="el-icon-delete" type="danger" size="small" @click.stop>删除</el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <div ref="footer" class="footer">
      <el-pagination :key="reloadPaginate" :current-page="pagination.page" :page-size="pagination.pageSize" :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper" :total="pagination.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        form: "",
        type: "",
        va_time: "",
      },
      cid: "",
      goods: {
        cid: "",
        code: "",
      },
      tableData: [],
      isLoading: {
        table: false,
        goodsTable: false,
      },
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 20,
        sku: "",
        cid: "",
      },
      drawer: false,
      goodsData: [],
      dialogVisible: false,
      dialogGoodsVisible: false,
      formList: [
        {
          value: "1",
          label: "报告",
        },
        {
          value: "2",
          label: "证书",
        },
        {
          value: "3",
          label: "报告+证书",
        },
      ],
      typeList: [],
      uploadFileList: [],
    };
  },
  //   watch: {
  //     "pagination.sku"() {
  // 		console.log(this.pagination.sku)
  // 	},
  //   },
  created() {
    this.getTableData();
    this.getTypeAll();
  },
  methods: {
    //查找sku
    findSku2c() {
      this.getTableData();
    },
    addCre() {
      this.dialogVisible = true;
      this.uploadFileList = [];
    },
    //刷新商品列表
    refreshGoodsUp() {
      this.$axios.put("/a1/cred/RefreshGoodsFiles").then((res) => {
        this.$message.success("更新时间大致需要1分钟");
      });
    },
    handleDelFile(index) {
      this.uploadFileList.splice(index, 1);
    },
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getTableData();
    },
    // //新增编辑弹窗关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    //获取到认证文件列表
    getTableData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/credential", {
          params: {
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
            sku: this.pagination.sku,
            cid: this.pagination.cid
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    //获取全部认证类型
    getTypeAll() {
      this.$axios.get("/a1/cred/GetTypeAll", {}).then((res) => {
        const { data } = res.data;
        this.typeList = data;
      });
    },
    //获取到认证商品列表
    getCertifiedGoods(id) {
      //设置认证ID begin
      this.goods.cid = id;
      this.cid = id;
      //设置认证ID end

      this.drawer = true;
      this.$axios.get(`/a1/cred/goods/${id}`).then((res) => {
        const { data } = res.data;
        this.goodsData = data;
      });
    },
    //打开商品认证新增
    handleAdd() {
      this.dialogGoodsVisible = true;
      this.goods.cid = this.cid;
    },
    //抽屉弹窗关闭
    handleCloseGoods(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    // 深拷贝
    handleEditAuth(row) {
      const formData = JSON.parse(JSON.stringify(row));
      this.form.form = formData.form + "";
      this.form.type = [];
      let typeArr = row.type.split(",");
      this.form.type = [];
      for (let i = 0; i < typeArr.length; i++) {
        this.form.type.push(parseInt(typeArr[i]));
      }
      //时间
      if (formData.va_time) {
        this.form.va_time = parseInt(formData.va_time) * 1000;
      } else {
        this.form.va_time = new Date();
      }

      this.form.id = formData.id;
      // this.uploadFileList = formData.files.map((item) => {
      //   return {
      //     url: item,
      //   };
      // });

      this.uploadFileList = [];
      //获取商品列表
      this.$axios.get("/a1/cred/info/" + row.id).then((res) => {
        let list_file = res.data.data.list_file;
        for (let i = 0; i < list_file.length; i++) {
          this.uploadFileList.push({
            url: list_file[i].src,
          });
        }
      });
      this.dialogVisible = true;
    },
    // 认证文件编辑跟新增
    handleDialogConfirm() {
      this.isLoading.table = true;
      if (this.form.id) {
        this.$axios
          .put(`/a1/credential/${this.form.id}`, {
            form: this.form.form,
            type: this.form.type.join(","),
            va_time: this.form.va_time / 1000 + "",
            files: this.uploadFileList.map((item) => item.url),
          })
          .then(() => {
            this.$message.success("修改成功");
            this.getTableData();
            this.dialogVisible = false;
          })
          .finally(() => {
            this.isLoading.table = false;
          });
        return;
      }

      this.$axios
        .post("/a1/credential", {
          form: this.form.form,
          type: this.form.type.join(","),
          va_time: this.form.va_time / 1000,
          files: this.uploadFileList.map((item) => item.url),
        })
        .then(() => {
          this.$message.success("新增成功");
          this.getTableData();
          this.dialogVisible = false;
        })
        .finally(() => {
          this.isLoading.table = false;
        });
    },
    // 认证文件清空表格数据
    handleDialogClose() {
      this.form = {
        form: "",
        type: "",
        va_time: "",
      };
      this.uploadFileList = "";
    },
    //认证商品资料清空表格数据
    handleDialogGoodsClose() {
      this.goods = {
        cid: "",
        code: "",
      };
    },
    // 认证文件删除
    handleDeleteAuth(id, index) {
      this.isLoading.table = true;
      this.$axios
        .delete(`/a1/credential/${id}`)
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除认证文件",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除认证文件",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.table = false;
        });
    },
    hidePopover() {
      this.$refs.footer.click();
    },
    //上传文件
    handleUpload(e) {
      const formdata = new FormData();
      formdata.append("file", e.file);
      this.$alert("正在上传，请稍候...", {
        showConfirmButton: false,
        showClose: false,
      });
      this.$axios
        .post("/a1/upload/file", formdata)
        .then((res) => {
          const { status } = res;
          if (status == "200") {
            const { data } = res.data;

            this.uploadFileList.push({
              url: data,
            });
          }
        })
        .finally(() => {
          this.$msgbox.close();
        });
    },
    //认证商品资料新增
    handleDialogGoodsConfirm() {
      this.isLoading.goodsTable = true;
      this.$axios
        .post(
          "/a1/cred/goods",
          Object.assign(
            {},
            {
              code: this.goods.code,
            },
            {
              cid: this.goods.cid + "",
            }
          )
        )
        .then(() => {
          this.$message.success("新增成功");
          // this.getCertifiedGoods(this.currentData.id);
          this.dialogGoodsVisible = false;
          this.getCertifiedGoods(this.cid);
        })
        .finally(() => {
          this.isLoading.goodsTable = false;
        });
    },
    //认证商品资料删除
    handleDelGoodsAuth(id, index) {
      this.isLoading.goodsTable = true;
      this.$axios
        .delete(`/a1/cred/delGoods/${id}`)
        .then(() => {
          this.goodsData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除认证商品",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除认证商品",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.goodsTable = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
	.file-list {
		margin-top: 10px;

		&__item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-grow: 0;
			flex-shrink: 0;
			padding: 20px 0;
			border-bottom: 1px solid #889aa4;

			&__label {
				width: 80px;
				margin-right: 20px;
			}

			&__name {
				margin-right: 20px;
			}

			&:last-child {
				padding: 0;
				border: 0;
			}
		}
	}

	.input_info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 300px;
		margin: 10px 0 10px 10px;
	}

	.input_text {
		width: 120px;
		color: #889aa4;
	}

	.footer {
		margin: 60px;
		text-align: center;
	}

	.addAdmin {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 50px;

		&_CharacterSet {
			font-size: 15px;
			width: 120px;
			text-align: right;
		}

		&_select {
			flex: 1;
		}
	}

	.table_style {
		margin-left: 10px;
	}

	.media {
		display: flex;
		align-items: center;
		justify-content: center;
	}
  
  ::v-deep {
    .el-drawer__container>.el-drawer{
      overflow-y: scroll;
      padding-bottom: 30px;
    }
  }
  
</style>
